<template>
  <div>
    <div class="title d-flex justify-content-between">
      Impressão de documentos da consulta
      <b-button
        variant="primary"
        class="new-setting-button"
        @click="openNewConfigCollapse"
      >
        <Plus class="icon" />
        Nova pré-definição de impressão
      </b-button>
    </div>
    <div class="print-setting-wrapper">
      <div
        class="doc"
        :style="{
          width: getPreviewPageSize.width,
          height: getPreviewPageSize.height
        }"
      >
        <div
          class="margin"
          :style="{
            left: parseSize(selectedSetting?.margin_left ?? 7),
            top: parseSize(selectedSetting?.margin_top ?? 12),
            right: parseSize(selectedSetting?.margin_right ?? 7),
            bottom: parseSize(selectedSetting?.margin_bottom ?? 12)
          }"
        >
          <div class="doc-header">
            <img
              v-if="!selectedSetting?.hide_brand"
              :src="brandTemporaryUrl"
              class="logo"
              :style="{
                width: '25%'
              }"
            />
            <div class="doc-title" :style="{ fontSize: parseSize(4.23) }">
              Título
            </div>
            <table style="width: 100%" v-if="!selectedSetting?.hide_professional_name">
              <tr>
                <td class="doc-professional">
                  <b>Realizado por: </b> Nome do profissional
                </td>
              </tr>
            </table>
            <PatientDataPreview
              :selectedSetting="selectedSetting"
              :parseSize="parseSize"
              v-if="selectedSetting?.show_patient_info"
            />
          </div>
          <div class="doc-content">Conteúdo</div>
          <div
            class="doc-footer"
            :style="{
              height: parseSize(45)
            }"
          >
            <div v-if="!selectedSetting?.hide_professional_signature">
              <div class="doc-date" :style="{ fontSize: parseSize(3.17) }">
                {{ city }}, {{ today.format('DD [de] MMMM [de] YYYY') }}
              </div>
              <div :style="{ padding: `${parseSize(10)} 0 ${parseSize(5)} 0` }">
                <div
                  class="line"
                  :style="{
                    width: parseSize(
                      selectedSetting?.format === 'A4' ? 100 : 80
                    )
                  }"
                />
              </div>
              <div
                class="doc-doctor-name"
                :style="{ fontSize: parseSize(3.17) }"
              >
                Nome do médico
              </div>
            </div>
            <div
              v-if="!selectedSetting?.hide_address"
              class="doc-address"
              :style="{ fontSize: parseSize(2.64) }"
            >
              {{ address }}, {{ number }}, {{ neighborhood }}, CEP
              {{ zipCode }}, {{ city }} - {{ state }} Telefone {{}}
            </div>
          </div>
        </div>
      </div>
      <div class="form">
        <div class="accordion" role="tablist">
          <div v-for="(setting, index) in clinicSettings" :key="index">
            <b-card class="form-card">
              <div
                class="d-flex justify-content-between align-items-center"
                role="tab"
              >
                <div
                  v-show="editConfigName !== index"
                  @click="onEditConfigName(index)"
                >
                  {{ setting.title ?? 'Impressão Padrão' }}
                </div>
                <b-input
                  ref="settingTitle"
                  class="no-border"
                  v-show="editConfigName === index"
                  autofocus
                  v-model="setting.title"
                  @blur="onEditConfigName(null)"
                >
                </b-input>
                <ChevronDown
                  class="chevron"
                  v-b-toggle="`accordion-${index}`"
                />
              </div>

              <b-collapse
                ref="accordion"
                :id="`accordion-${index}`"
                accordion="accordion-configs"
                :visible="setting.is_general_setting"
                @shown="onChangeAccordion(index)"
                role="tabpanel"
              >
                <DocumentPrintForm
                  :form="setting"
                  :index="index"
                  :clinic="clinic"
                  :documentOptions="documentOptions"
                  @newSettingCreated="getPrintSettings()"
                  @deleteSelected="removeSetting(index)"
                  @createPreview="createPreview(index)"
                />
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import { saveAs } from 'file-saver'

export default {
  name: 'NewDocumentPrintView',
  components: {
    DocumentPrintForm: () =>
      import('@/views/Configs/Components/DocumentPrintForm'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Plus: () => import('@/assets/icons/plus-simple.svg'),
    PatientDataPreview: () =>
      import('@/views/Configs/Components/PatientDataPreview')
  },
  computed: {
    getPreviewPageSize() {
      return {
        width: this.parseSize(
          this.pageSizes[this.selectedSetting?.format]?.width ?? 210
        ),
        height: this.parseSize(
          this.pageSizes[this.selectedSetting?.format]?.height ?? 290
        )
      }
    },
    selectedSetting() {
      return this.clinicSettings[this.selectedIndex]
    }
  },
  data() {
    return {
      today: this.moment(),
      clinic: getCurrentClinic(),
      brandTemporaryUrl: null,
      validate: false,
      loading: false,

      clinicSettings: [],
      selectedIndex: 0,
      editConfigName: null,

      address: null,
      city: null,
      complement: null,
      country: null,
      neighborhood: null,
      number: null,
      state: null,
      zipCode: null,
      phone: null,

      pageSizes: {
        A4: { width: 210, height: 297 },
        A5: { width: 148, height: 210 }
      },

      documentOptions: []
    }
  },
  async mounted() {
    await this.loadData()
    this.getClinicData()
  },
  methods: {
    parseSize(value) {
      return value + 'mm'
    },
    getDefaultForm() {
      return {
        id: null,
        title: 'Nova definição de impressão',
        documents: [],
        format: 'A4',
        orientation: 'P',
        font_size: 100,
        margin_top: 7,
        margin_right: 12,
        margin_bottom: 12,
        margin_left: 7,
        hide_brand: false,
        hide_address: false,
        hide_professional_signature: false,
        hide_professional_name: false,

        show_patient_info: true,
        show_patient_name: true,
        show_patient_cpf: false,
        show_patient_rg: false,
        show_patient_health_plan: false,
        show_patient_age: false,
        show_patient_gender: false,
        show_patient_medical_record: false,
        show_exam_date: false
      }
    },

    onChangeAccordion(index) {
      this.selectedIndex = index
    },
    onEditConfigName(index) {
      this.editConfigName = index
      if (index !== null) {
        this.$nextTick(() => {
          this.$refs.settingTitle[index].focus()
        })
      }
    },
    openNewConfigCollapse() {
      this.clinicSettings.push(this.getDefaultForm())
      this.$nextTick(() => {
        this.$refs.accordion[this.clinicSettings.length - 1].show = true
      })
    },
    async getClinicData() {
      const res = await this.api.showClinic(this.clinic.id)
      this.address = res.data.address.address
      this.city = res.data.address.city
      this.complement = res.data.address.complement
      this.country = res.data.address.country
      this.neighborhood = res.data.address.neighborhood
      this.number = res.data.address.number
      this.state = res.data.address.state
      this.zipCode = res.data.address.zipCode
      this.phone = res.data.phone
      this.brandTemporaryUrl = await this.getBrandTemporaryUrl(res.data.brand)
    },

    async loadData() {
      const l = this.$loading.show()
      this.api
        .getPrintableDocuments()
        .then(async res => {
          this.documentOptions = res.data
          await this.getPrintSettings(true)
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
        .finally(() => l.hide())
    },

    updateSelectedConfig(setting) {
      this.selectedSetting = setting
    },

    removeSetting(index) {
      this.clinicSettings.splice(index, 1)
    },

    async createPreview(index) {
      const l = this.$loading.show()
      await this.api
        .createSettingPreviewDoc(this.clinicSettings[index])
        .then(res => {
          saveAs(
            new Blob([res.data], {
              type: 'application/octet-stream'
            }),
            `preview ${this.clinicSettings[index].title}.pdf`
          )
        })
        .catch(error => {
          this.$toast.error(
            `Não foi possível gerar a pré-visualização, por favor tente novamente`
          )
          console.error(error)
        })
        .finally(() => {
          l.hide()
          this.$toast.success(`Pré-visualização gerada com sucesso`)
        })
    },

    async getPrintSettings(selectGeneralSetting = false) {
      this.api
        .getPrintSettings(this.clinic.id)
        .then(res => {
          this.clinicSettings = res.data
          const generalSettingIndex = this.clinicSettings.findIndex(
            setting => setting.is_general_setting
          )
          if (selectGeneralSetting && generalSettingIndex > -1) {
            this.selectedIndex = generalSettingIndex
          }
          if (generalSettingIndex > -1) {
            this.clinicSettings[generalSettingIndex].documents =
              this.fillGeneralSettingDocuments()
          }
          this.$toast.success('Configurações carregadas com sucesso')
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
    },

    fillGeneralSettingDocuments() {
      const documentsWithCustomSetting = this.clinicSettings
        .map(s => {
          return s.documents
        })
        .flat()
      return this.documentOptions.filter(
        doc => !documentsWithCustomSetting.map(el => el.id).includes(doc.id)
      )
    },

    getBrandTemporaryUrl(brand) {
      return new Promise((resolve, reject) => {
        const path = `public/images/clinics/${brand}`
        this.api
          .getDocTemporaryUrl(path)
          .then(res => resolve(res.data))
          .catch(reject)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.print-setting-wrapper {
  display: flex;
  flex-direction: row;
  gap: 40px;
  font-weight: 600;
}
.doc {
  position: relative;
  width: 210mm;
  height: 290mm;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.form-card {
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.margin {
  position: absolute;
  border: 2px dashed var(--neutral-200);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: 10px;
  color: white;
}
.doc-header {
  border: 2px solid white;
  border-radius: 4px;
  background-color: var(--neutral-100);
}
.logo {
  width: auto;
  border-radius: 4px;
}
.doc-title {
  font-weight: bold;
  text-align: center;
  color: var(--type-active);
  margin-bottom: 10px;
}
.doc-professional {
  color: var(--type-active);
}
.doc-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--type-active);
  border: 2px solid white;
  border-radius: 4px;
  background-color: var(--neutral-100);
}
.doc-footer {
  width: 100%;
  color: var(--type-active);
  text-align: center;
  border: 2px solid white;
  border-radius: 4px;
  background-color: var(--neutral-100);
}
.line {
  height: 1px;
  background: var(--type-active);
  margin: 0 auto;
}
.no-border {
  border: none !important;
}
.checkbox-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--type-active);
  margin-bottom: 10px;
}
.form {
  border-left: 1px solid var(--neutral-200);
  padding-left: 20px;
  min-width: 50%;
}

.chevron {
  width: 24px;
  height: 24px;
  stroke: var(--neutral-600);
  transition: all 0.5s;
  cursor: pointer;

  &.not-collapsed {
    animation: rotateUp 0.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  &.collapsed {
    animation: rotateDown 0.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    /* transform: rotate(180deg); */
  }
  &.chevron:focus {
    outline: none !important;
  }
}
</style>
